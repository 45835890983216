<template>
  <div>
    <div>
      <v-btn
          :outlined="outlined"
          :width="width"
          :height="height"
          @click.stop="dialog = true"
          style="letter-spacing: 0"
          :ripple="false"
          :icon="$route.fullPath === '/dashboard/' && $vuetify.breakpoint.mobile"
          :text=!$vuetify.breakpoint.mobile
          :class="outlined?'button-text-outlined shareBorder':'button-text-depressed'"
          class="tw-capitalize tw-rounded-lg">
        <div class="tw-flex tw-items-center">
          <vue-feather class="tw-cursor-pointer mr-1" size="20" type="share-2"/>
          {{ buttonText }}
        </div>
      </v-btn>
    </div>
    <v-dialog persistent v-model="dialog" width="776">
      <template v-slot:default>
        <v-card class="share-modal">
          <div class="tw-flex lg:tw-px-5 tw-justify-between">
            <div class="share-header tw-my-5">Share this job opening</div>
            <XIcon @click="closeModal" class="tw-mt-3 tw-cursor-pointer"/>
          </div>
          <div class="lg:tw-ml-10">
            <p class="share-text">Copy the link generated, and send it to people you want to see it.</p>
            <div class="d-flex tw-mb-5">
              <v-avatar class="tw-mr-3" color="#ECF4F7" size="100" tile>
                <img :src="logo" alt=""/>
              </v-avatar>
              <div>
                <p class="lg:ml-sm-3 mt-sm-3 mb-0 job-title">{{ jobTitle }}</p>
                <p class="lg:ml-sm-3 mb-1 job-role">{{ company }} . {{ jobType }}</p>
                <div class="tw-flex">
                  <MapPinIcon class="mt-1 lg:ml-2" size="14" style="color: #9CABB5;"/>
                  <p class="job-type tw-ml-1">{{ location }}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="md:tw-ml-5 copy-div tw-flex tw-justify-between md:tw-w-620">
              <span :class="$vuetify.breakpoint.smAndDown?'truncate':''" class="link">{{ baseUrl }}</span>
              <div v-if="isCopy">
                <span class="copied">Copied</span>
              </div>
              <div v-else class="tw-flex tw-cursor-pointer" @click="copy(baseUrl)">
                <vue-feather class="" type="copy"></vue-feather>
                <h2 class="tw-ml-4 tw-hidden md:tw-block">Copy link</h2>
              </div>
            </div>
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>

import {MapPinIcon, XIcon} from "vue-feather-icons"

export default {
  name: "ShareJobModal",
  components: {XIcon, MapPinIcon},
  data() {
    return {
      dialog: false,
      baseUrl: 'http://localhost:8082/job-details/213',
      isCopy: false
    }
  },
  props: {
    jobTitle: [String],
    company: [String],
    jobType: [String],
    location: [String],
    logo: [String],
    id: [Number, String],
    block: [Boolean],
    buttonText: {
      type: String,
      default: 'Share'
    },
    outlined: [Boolean],
    width: {
      type: String,
      default: '118.5px'
    },
    height: {
      type: String,
      default: '48px'
    },
    loading: [Boolean],
  },
  mounted() {
    if (process.env.NODE_ENV === "development") {
      this.baseUrl = `http://localhost:8080/search/details?jobId=${this.id}`

    } else {
      this.baseUrl = `${process.env.VUE_APP_PROD_TALENT_FRONTEND_URL}/search/details?jobId=${this.id}`
    }
  },
  methods: {
    async copy(s) {
      if (process.env.NODE_ENV === "development") {
        s = `http://localhost:8080/search/details?jobId=${this.id}`

      } else {
        s = `${process.env.VUE_APP_PROD_TALENT_FRONTEND_URL}/search/details?jobId=${this.id}`
      }
      await navigator.clipboard.writeText(s);
      this.isCopy = true
    },
    closeModal() {
      this.dialog = false
      this.isCopy = false
    }
  }
}
</script>

<style scoped lang="scss">
.share-modal {
  background: #FFFFFF;
  border-radius: 21px;
  padding: 20px 20px 35px;
}

.share-header {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
}

.shareBorder {
  border: 1px solid #EEEEEE!important;
  @media (max-width: 550px) {
    background-color: #FBFBFB !important;
  }
}

.share-text {
  font-family: "DM Sans", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /*text-align: center;*/
  color: #9CABB5;
}

.job-title {
  font-family: "DM Sans", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #1E323F;
}

.job-role {
  font-family: "DM Sans", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #9CABB5;
}

.job-type {
  font-family: "DM Sans", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #9CABB5;
}

.copy-div {
  background: #FFFFFF;
  border: 1px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 17px 12px 15px 15px;
}

.link {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #475661;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px !important;

}

.copied {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #008EEF;
}

.v-btn {
  color: #475661;
  font-weight: 700;
  text-transform: capitalize;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
}

.truncate {
  width: 185px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
