import router from "@/router";


export const getFileSize = (size) => {
    if (!size) return ''
    size = Number(size)
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export const gotoJobPage = () => {
    return router.push({name: 'SearchResult'})
}