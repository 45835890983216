<template>
  <SearchLayout>
    <template #header>
      <Header @click="getSearch">-->
        <template #route-back-component>
          <div v-if="$route.query.jobId">
            <vue-feather type="arrow-left" @click="goBack()"/>
          </div>
        </template>
        <template v-slot:right-header-component>
          <ProfileDropDown v-if="getUserDetails"/>
          <GuestLogin v-else/>
        </template>
      </Header>
    </template>
    <template #dashboard-sideNav>
      <div class="tw-mt-6">
        <VerticalMenu/>
      </div>
    </template>
    <template #dashboard-content-header>
      <TopNavSearch v-if="$route.query.search || !$vuetify.breakpoint.mobile"
                    :field="fieldData"/>
    </template>
    <template #dashboard-content>
      <v-card
          v-if="search !== ''"
          flat
          class="tw-mb-6"
          style="
          height: 60px;
          border: 1px solid #E0E9FF;
          box-sizing: content-box;
          border-radius: 8px;
          ">
        <v-card-text class="tw-flex" style="box-sizing: content-box">
          <small class="searchText text-capitalize">{{ search + ' ' }} <span class="font-weight-regular"> - {{alertSwitch?'Alert on for this job role':'Turn on job alert for this role'}} </span></small>
          <v-switch
              class=" ml-2 mt-n1"
              dense
              :disabled="disableAlert"
              color="#8F47F4"
              v-model="alertSwitch"
              @change="switchNotification"
              flat
          ></v-switch>
        </v-card-text>
      </v-card>
      <div v-if="!isLoading && (searchResult.results.length  <= 0 || searchResult.length <= 0)">
        <span class="font-weight-bold"> no match found</span>
      </div>
      <div v-else :class="[$vuetify.breakpoint.mdAndDown?'':' tw-flex tw-w-full']">
        <div >
          <JobDetailsCard :search="search" :filteredSearchData="filteredSearch"/>
        </div>
      </div>
    </template>
    <template #footer>
      <div v-if="num && !$route.query.jobId">
        <v-pagination
            v-model="page"
            color="#008EEF"
            @input="getPage()"
            :length="Math.ceil(searchResult.count/num)"
            @previous="getPage()"
            @next="getPage()"
            :total-visible="5"
        ></v-pagination>
      </div>
    </template>
  </SearchLayout>
</template>
<script>
import Header from "@/components/reusables/Header";
import VerticalMenu from "@/components/reusables/VerticalMenu";
import {mapGetters} from "vuex";
import ProfileDropDown from "@/components/reusables/ProfileDropDown";
import TopNavSearch from "@/components/dashboard/reuseables/TopNavSearch";
import SearchLayout from "@/components/layout/SearchLayout";
import GuestLogin from "@/components/dashboard/reuseables/GuestLogin";
import JobDetailsCard from "@/components/dashboard/reuseables/JobDetailsCard";
import {jobAlertExist, sendJobAlert} from "@/services/api/APIService";

export default {
  name: "SearchResult",
  components: {
    JobDetailsCard,
    GuestLogin,
    SearchLayout,
    TopNavSearch, ProfileDropDown, Header, VerticalMenu
  },
  data() {
    return {
      isMobile: false,
      disableAlert: false,
      showJobDetails: false,
      loading: false,
      search: '',
      alertSwitch: false,
      page: 12,
      num: 12,
      fieldData: [
        {
          title: 'Jobs',
          type: 'job',
          selected: '',
          enable: false,
          filteredOn: [
            {id: 1, name: "Job"},
            {id: 2, name: "People"},
            {id: 3, name: "Company"}],
        },
        {
          title: 'Job Type',
          type: 'job_type',
          selected: '',
          enable: false,
          filteredOn: [
            {id: 1, name: "Full-Time"},
            {id: 2, name: "Part-Time"},
            {id: 3, name: "Contract"},
            {id: 4, name: "Internship"},
            {id: 5, name: "Flexible"}],
        },
        {
          title: 'Experience Level',
          type: 'Experience_level',
          selected: '',
          enable: false,
          filteredOn: [
            {id: 1, name: "Junior"},
            {id: 2, name: "Mid"},
            {id: 3, name: "Senior"}
          ],
        },
        {
          title: 'Date Posted',
          type: 'date_posted',
          selected: '',
          enable: false,
          filteredOn: [
            {name: 'Today'},
            {name: 'This Week'},
            {name: 'This Month'},
            {name: 'This Quarter'},
            {name: 'Anytime'}
          ],
        },
        {
          title: 'On-site/Remote',
          type: 'is_remote',
          selected: '',
          enable: false,
          filteredOn: [
            {id: 1, name: "on-site"},
            {id: 2, name: "Remote"}
          ],
        },
      ],
    }
  },
  watch: {
    '$route': {
      handler: function (route) {
        this.showJobDetails = !(route.fullPath === '/search' || route.query.search);
      }
    }
  },
  computed: {
    ...mapGetters('jobSearch', ['searchResult','isLoading']),
    // eslint-disable-next-line vue/return-in-computed-property
    filteredSearch() {
      const filteredProducts = this.searchResult.results.filter(() => true); // replace with your existing filter conditions here

      // apply sort function
      const sortFunction = this.getSorter();
      return sortFunction ? filteredProducts.sort(sortFunction) : filteredProducts;
    },
    getUserDetails() {
      return JSON.parse(sessionStorage.getItem('userData'))
    }
  },
  async created() {
    if (this.$route.query.jobId && this.getUserDetails) {
      this.loading = true
      this.showJobDetails = true
      let preference = JSON.parse(sessionStorage.getItem('userProfile'))
      let data = {}
      data.job_preference = preference.job_preference
      data.user_email = this.getUserDetails.email
      await this.$store.dispatch('jobSearch/getJobPreference', data)
      this.loading = false
    }
    if (this.$route.query.search){
      this.search = this.$route.query.search
    }
    this.loading = true
    let data = {}
    data.page = this.page
    await this.$store.dispatch('jobSearch/getAllJob', data)
    this.loading = false
  },

  methods: {
    goBack(){
      this.$router.push({name:'SearchResult'})
    },
    toggleLoadingPreference(id){
      const job = this.filteredSearch.find(j => j.id === Number.parseInt(id))
      job.loading = !job.loading
    },
    switchNotification() {
      console.log(this.alertSwitch)
      sendJobAlert({job_alert: this.search}).then(res => {
        console.log(res)
        this.$displaySnackbar({
          message: 'Job alert saved',
          success: true
        })
        this.disableAlert = true
      }).catch(err => {
        console.log(err)
        let errorKey = Object.keys(err.response.data)
        this.$displaySnackbar({
          message: err.response.data[errorKey[0]],
          success: false
        })
      })
    },
    toggleSavingPreference(id){
      console.log(this.isSaved+"...before")
      const job = this.filteredSearch.find(job => job.id === Number.parseInt(id))
      job.is_saved = !job.is_saved
      console.log(job)
      console.log(this.isSaved)

    },

    getSearch(data) {
      console.log(data)
      this.search = data
      if (this.getUserDetails) {
        let data = {}
        data.email = this.getUserDetails.email
        data.search = this.search
        jobAlertExist(data).then(res => {
          console.log(res.data)
          this.disableAlert = res.data.is_job_alert
          this.alertSwitch = res.data.is_job_alert
        }).catch(e => console.log(e))
      }
    },
    toggleShow() {
      this.showJobDetails = true
    },
    getPage() {
      let data = {}
      data.page = this.page
      data.page_size = this.num
      if (this.getUserDetails) {
        data.email = this.getUserDetails.email
        if (this.search !== '') {
          data.query = this.search
          this.$store.dispatch('jobSearch/searchJobs', {payload: data, $route: this.$route})
        }else this.$store.dispatch('jobSearch/fetchPaginatedJobs', data)
      } else {
        this.$store.dispatch('jobSearch/fetchPaginatedJobs', data)
      }
    },
    remove(item, arrayToRemoveToRemoveFFrom) {
      const index = arrayToRemoveToRemoveFFrom.indexOf(item.id);
      if (index >= 0) arrayToRemoveToRemoveFFrom.splice(index, 1);
    },
    getSorter() {
      switch (this.sortBy) {
        case 'Latest':
          return
        case 'Alphabetically':
          return (a, b) => b.title > a.title ? -1 : a.title === b.title ? 0 : 1;
        default:
          return null;
      }
    },
  }
}
</script>
<style>
.scroll {
  max-height: 68vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 15px !important;
  border-radius: 6px;
}

.searchText {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #000000;

}
</style>
