<template>
  <div class="mt-5">
    <slot name="header"></slot>
    <div v-if="getUserDetails" style="margin-top: 75px">
      <slot name="dashboard-content-header"></slot>
      <v-row no-gutters class="tw-p-3 lg:tw-p-5 " >
        <v-col cols="2" class="tw-hidden lg:tw-block pr-2">
          <slot name="dashboard-sideNav"></slot>
        </v-col>
        <v-col sm="12" md="10">
          <div class="tw-mt-6">
            <slot name="dashboard-content"></slot>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div style="margin-top: 85px">
          <slot name="dashboard-content-header"></slot>
        <div class="tw-p-4 lg:tw-p-12 lg:tw-pr-16 lg:tw-pl-52">
          <slot name="dashboard-content"></slot>
        </div>
      </div>
    </div>
    <div>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchLayout",
  beforeMount() {
    let routeQuery = this.$route.query
    if (routeQuery.redirect){
      delete routeQuery.redirect
      setTimeout(() => {
        this.$router.push({
          name: 'SearchResult',
          query:routeQuery
        })
      }, 200)
    }
  },
  computed: {
    getUserDetails() {
      return JSON.parse(sessionStorage.getItem('userData'))
    }
  },
}
</script>

<style scoped>
.main__content {
  min-height: calc(100vh - 64px);
}
</style>
