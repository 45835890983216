<template>
  <svg width="163" height="164" viewBox="0 0 163 164" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.697937" width="163" height="163" rx="81.5" fill="#BFFFDD"/>
    <rect x="19" y="19.6979" width="126" height="126" rx="63" fill="#28E17E"/>
    <path d="M98.6663 69.5L75.7497 92.4167L65.333 82" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>


</template>

<script>
export default {
name: "checkedIcon"
}
</script>

<style scoped>

</style>