<template>
  <div>
    <div class="tw-flex tw-items-center tw-cursor-pointer profileHeader">
      <div>
        <span class="text">Already have an account?  <span class="action tw-mr-4 tw-ml-2" @click="login">Login</span></span>
      </div>
      <div>
        <BaseButton button-text="Sign Up" depressed block height="40" type="button" @click="signup"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";
export default {
  name: "GuestLogin",
  components: {BaseButton},
  methods: {
    login(){
      return window.location.href = `${process.env.VUE_APP_FRONTEND_AUTH_URL}/auth/login?site=individual`;
    },
    signup(){
      return window.location.href = `${process.env.VUE_APP_FRONTEND_AUTH_URL}/auth/signup?site=individual`;
    }
  }
}
</script>

<style scoped>
.profileHeader{
  display: flex;
  align-items: center;
}
.text{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #8692A6;
}
.action{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #008EEF;
  cursor: pointer;
  /*margin-left: 13px;*/
}
</style>