<template>
  <div class="tw-flex">
    <div class="tw-w-80">
      <div class="explore-card pt-5" v-for="(job_in_location, i) in explore_by_search" :key="i">
        <div @click="viewJobDetails(job_in_location)">
          <div class="explore-action mb-4">
            <div class="company-logo2">
              <img v-if="job_in_location.company_logo.startsWith('https')" :src="job_in_location.company_logo"
                   style="width: 32px; height: 32px;" alt="logo"/>
              <default-avatar v-else :company-name="job_in_location.company_name"/>
            </div>
            <div class="save-icon">
              <v-btn v-if="getUserDetails" :loading="job_in_location.loading" text class="d-flex align-center"
                     @click.stop="handleAction(job_in_location.id,'save')">
                <div class="tw-flex tw-items-center" style="text-transform: capitalize;color: #9CABB5"
                     v-if="!job_in_location.is_saved">
                  <vue-feather type="bookmark" size="19"/>
                  save
                </div>
                <div v-else class="tw-flex tw-items-center save-icon"
                     style="text-transform: capitalize;width: 68px;height: 32px; color: #008EEF; background-color: #F6FCFF ">
                  <v-icon small>mdi-bookmark</v-icon>
                  saved
                </div>
              </v-btn>
            </div>
          </div>
          <div class="job-details">
            <span class="job-title">{{ job_in_location.title }}</span>
            <span class="details tw-capitalize">{{ job_in_location.company_name }} <span
                class="bullet">&bull;</span> <span
                class="job-type">{{ job_in_location.type }}</span> </span>
          </div>
          <div class="explore-action pt-10">
          <span v-if="job_in_location.is_remote === true" class="type"><vue-feather type="map-pin" size="18"
                                                                                    class="mr-1"/>Remote</span>
            <span v-else class="type"><vue-feather type="map-pin" size="18" class="mr-1"/>On-site</span>
            <span class="type time mr-3">{{ processDay(job_in_location.created_at) }} </span>
          </div>
        </div>
      </div>
      <div v-if="searchResult.count > num">
        <v-pagination
            v-model="page"
            color="#008EEF"
            @input="getPage()"
            :length="Math.ceil(searchResult.count/num)"
            @previous="getPage()"
            @next="getPage()"
            :total-visible="5"
        ></v-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import {savedJob} from "@/services/api/APIService";
import DefaultAvatar from "../../reusables/DefaultAvatar";
import {mapGetters} from "vuex";
import timeDay from '@/mixins/timeDay.mixin'

export default {
  name: "ExploreBySearch",
  components: {DefaultAvatar},
  mixins: [timeDay],
  props: {
    explore_by_search: Array,
    search: String
  },
  data() {
    return {
      page: 12,
      saveJobLoading: false,
      num: 12,
      loading: false,
    }
  },

  computed: {
    ...mapGetters('jobSearch', ['searchResult']),
    getUserDetails() {
      return JSON.parse(sessionStorage.getItem('userData'))||null
    }

  },
  methods: {
    getPage() {
      let data = {}
      data.page = this.page
      data.page_size = this.num
      let getUserDetails = JSON.parse(sessionStorage.getItem('userData'))
      if (getUserDetails) {
        data.email = getUserDetails.email
        if (this.search !== '') {
          data.query = this.search
          this.$store.dispatch('jobSearch/searchJobs', {payload: data, $route: this.$route})
        } else this.$store.dispatch('jobSearch/fetchPaginatedJobs', data)
      } else {
        this.$store.dispatch('jobSearch/fetchPaginatedJobs', data)
      }
      // sessionStorage.setItem('searchKeyword', '')
    },
    viewJobDetails(data) {
      this.goto(data.id)
    },
    handleAction(job_id, type) {
      if (type === "save") {
        this.savedJob(job_id)
      } else {
        this.goto(job_id)
      }
    },
    goto(jobId) {
      this.$router.push({
        query: {jobId}
      });
      this.$emit('scrollToTop')
    },
    async savedJob(job_id) {
      this.$emit('loading', job_id)
      const userData = JSON.parse(sessionStorage.getItem('userData'))
      let data = {}
      data.job_id = job_id
      data.candidate_email = userData.email
      savedJob(data).then(() => {
        this.$emit('saving', job_id)
        this.$emit('loading', job_id)
      })
          .catch(err => {
            console.log(err)
            this.toggleLoading(job_id, false)
            let errorKey = Object.keys(err.response.data)
            this.$displaySnackbar({
              message: err.response.data[errorKey[0]],
              success: false
            })
          }).finally(() => {
      })
    },
    toggleLoading(id, state) {
      let job = this.addLoadingToJob.find(job => job.id = id)
      job.loading = state
      console.log(job, state)
    },
  }
}
</script>

<style scoped lang="scss">
.explore-title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #475661;
  padding: 1rem 0;
  border-bottom: 1px solid #F6F6F6;

}

.explore-card {
  margin: 0.6rem;
  width: 100%;
  max-width: 352px;
  min-height: 180px;
  background: #FFFFFF;
  border: 1px solid #FBFBFB;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px 1px rgba(0, 142, 239, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
  @media (max-width: 765px) {
    max-width: 400px;
  }
}

.explore-action {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.save-icon {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #9CABB5;

}

.company-logo2 {
  width: 40px;
  height: 40px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cardGlow {
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 1px rgba(0, 142, 239, 0.12);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
}

.job-details {
  display: flex;
  flex-direction: column;

  .details {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #475661;
  }
}

.type {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #475661;
  display: flex;


}

.job-title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #1E323F;

}

.scroll {
  max-height: 67vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 15px !important;
  border-radius: 6px;
}

//.bullet{
//
//    content: "\2022";
//  color: #000000;
//
//}

</style>


