<template>
  <div>
    <div class="wrapper">
      <div class="border--bottom">
        <h4 class="data__title">CONFIRM DETAILS</h4>
      </div>
      <div class="d-flex my-3 py-2">
        <v-avatar class="mr-3" size="60">
          <v-img :src="userInfo.image"/>
        </v-avatar>
        <div class="d-flex flex-column basic__info">
          <h3 class="fullName font-weight-bold text-capitalize">{{userInfo.first_name}} {{userInfo.last_name}}</h3>
          <small>{{userInfo.email}}</small>
          <small>{{userInfo.location}}</small>
          <small>{{userInfo.phone_number}}</small>
          <small>{{userInfo.age}}</small>
        </div>
      </div>
    </div>
    <div v-if="resume">
      <div class="border--bottom py-3">
        <h4 class="data__title">RESUME</h4>
      </div>
      <div class="d-flex justify-space-between py-6">
        <div class="d-flex align-center">
          <img :src="[getDocIcon(resume.cv_format)]" :alt="[getDocIcon(resume.name)]">
          <div class="ml-2">
            <p class="mb-0">{{resume.name}}</p>
            <small>{{resume.cv_size}}</small>
          </div>
        </div>
        <div>
          <v-btn @click="[edit(1)]" text color="#008eef">CHANGE</v-btn>
        </div>
      </div>
    </div>
<!--    <div>-->
<!--      <div class="border&#45;&#45;bottom py-3">-->
<!--        <h4 class="data__title">ADDITIONAL DOCUMENT</h4>-->
<!--      </div>-->
<!--      <div class="d-flex justify-space-between py-6">-->
<!--        <div class="d-flex align-center">-->
<!--          <img :src="[getDocIcon(coverLetter.format)]" :alt="[getDocIcon(resume.format)]">-->
<!--          <div class="ml-2">-->
<!--            <p class="mb-0">{{coverLetter.name}}</p>-->
<!--            <small>{{coverLetter.size}}</small>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div>-->
<!--          <v-btn @click="[edit(2)]" text color="#008eef">CHANGE</v-btn>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div v-if="Object.keys(additionalInfo).length !== 0">
      <div class="border--bottom py-3">
        <h4 class="data__title">ADDITIONAL INFORMATION</h4>
      </div>
      <v-row dense class="py-8">
        <v-col v-for="(v, k, i) in additionalInfo" :key="i" cols="12" md="6">
          <h5>{{k}}</h5>
          <p class="user__response font-weight-bold" v-if="typeof v === 'string'">{{v}}</p>
          <p v-else class="user__response font-weight-bold">
            <span v-for="(item, index) in v" :key="item">{{item}}{{index === v.length - 1 ? '' : ', '}}</span>
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import profileImg from '@/assets/profile.svg'
import docFile from "@/assets/images/docFile.svg";
import pdfFile from "@/assets/images/pdfFile.svg";
// import {getFileSize} from "@/services/utils";

export default {
  name: "PreviewApplicationData",
  props: {
    additionalInfo: Object,
    resume: Object,
    coverLetter: Object
  },
  data: () => ({
    profileImg
  }),
  computed: {
    ...mapState('createProfile', ['user']),
    userInfo () {
      const userProfile = JSON.parse(sessionStorage.getItem('userProfile'))
      const userData = JSON.parse(sessionStorage.getItem('userData'))
      return {...userProfile, ...userData}
    }
  },
  methods: {
    getDocIcon(extension) {
      const extensions = {
        'docx': docFile,
        'pdf': pdfFile
      }
      return extensions[extension]
    },
    edit(step) {
      this.$emit('step-back', step)
    }
  }
}
</script>

<style scoped lang="scss">
.fullName {
  font-size: 22px;
  color: #1E323F;
}

.basic__info {
  small {
    color: #475661;
  }
}

.data__title {
  color: #475661;
  font-weight: 500;
  letter-spacing: 3px;
}

.user__response {
  font-size: 18px;
  color: #1E323F;
}

.wrapper {
  margin-top: 10px;
}
</style>
