<template>
  <div class="tw-w-full">
    <div>
      <div class="tw-flex py-5 my-1 tw-justify-between">
        <div class="d-flex align-center">
          <img :src="[getDocIcon(file)]" :alt="[getDocIcon(file)]">
          <div class="ml-2">
            <p class="mb-0">{{file.name}}</p>
            <small>{{file.size || file.cv_size}}</small>
          </div>
        </div>
        <div>
          <slot name="actionIcon" v-if="!file.loading">
            <svg @click="[selectFile(file.id)]" class="tw-cursor-pointer" v-if="select" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle
                  cx="12.4531"
                  cy="12"
                  :r="file.selected ? 12 : 11.25"
                  :stroke-width="!file.selected ? '1.5' : ''"
                  :fill="file.selected ? '#008EEF' : ''"
                  :stroke="!file.selected ? '#008EEF' : ''"/>
              <path v-if="file.selected" d="M7.31641 12.868L10.1641 15.7131L17.5911 8.28613" stroke="white"/>
            </svg>
          </slot>
          <v-progress-circular color="#008eef" indeterminate v-else/>
        </div>
      </div>
      <input type="file" ref="cvPicker" @change="onFileInputChange" class="d-none">
      <div v-if="!readOnly">
        <div class="tw-flex tw-justify-between">
          <small v-if="$route.query.jobId">Upload Resume (For job applications only)</small>
          <small v-else>Upload File</small>
          <div>
            <small>Choose from file</small>
            <v-btn @click="pickFile" class="upload__btn text-capitalize" color="#008eef" depressed>Upload</v-btn>
          </div>
        </div>
        <p>DOC, DOCX, PDF (2MB)</p>
      </div>
    </div>
  </div>
</template>

<script>
import docFile from "@/assets/images/docFile.svg"
import pdfFile from "@/assets/images/pdfFile.svg"
// import {uploadCV} from "@/services/api/APIService";
import {getFileSize} from "@/services/utils";
import {uploadMediaFile} from "@/services/mediaFileUploader";
export default {
  name: "DocumentUploader",
  data: () => ({
    docFile,
    // cvs: [],
    file: {
      loading: false
    },
  }),
  props: {
    select: Boolean,
    readOnly: Boolean,
    document: Object
  },
  watch: {
    document: {
      handler: function (file) {
        if (file && file.name) {
          this.file = file
        }
      },
      immediate: true
    }
  },
  methods: {
    getDocIcon (file) {
      let extension = file.format
      if (!file.format) extension = this.getFileExt(file.name)
      const extensions = {
        'docx': docFile,
        'pdf': pdfFile
      }
      return extensions[extension] ?? pdfFile
    },
    pickFile () {
      this.$refs.cvPicker.click()
    },
    onFileInputChange ({target: {files}}) {
      const [file] = files
      try {
        this.verifyDoc(file)
        this.uploadCV(file)
      } catch (e) {
        this.$displaySnackbar({message: e.message, success: false})
      }
    },
    getFileExt (filename) {
      console.log(filename)
      if (!filename) return
      return filename.split('.').pop()
    },
    verifyDoc (file) {
      const filename = file.name
      const extension = this.getFileExt(filename)
      const acceptables = ['pdf', 'doc', 'docx']
      if (!acceptables.includes(extension)) {
        throw new Error("The extension of the chosen file is not acceptable")
      }
      if (file.size > 2 * 10**6) {
        throw new Error("The size of the chosen file is larger than recommended")
      }
    },
    selectFile () {
      this.$emit('selected')
    },
    async uploadCV (file) {
      this.file.loading = true
      try {
        const {name, format, size,key} = await uploadMediaFile(file, 'cv_documents')
        this.$emit('input', {size: getFileSize(size), url:key, name, format})
      } catch (e) {
        this.$displaySnackbar({message: e.response.data.detail})
        console.log(e)
      } finally {
        this.file.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-btn.upload__btn {
  background: #F6FCFF !important;
  color: #008eef;
}

</style>
