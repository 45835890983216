<template>
  <div>
    <div v-if="cvs && cvs.length > 0">
      <document-uploader v-for="(cv, i) in cvs" :key="i" read-only :document="cv" @selected="[selectFile(cv.id)]" select/>
    </div>
    <document-uploader @input="uploadFile" v-if="cvs.length !== 3"/>
  </div>
</template>

<script>
import DocumentUploader from "@/components/reusables/DocumentUploader";
// import {getCandidateCVs} from "@/services/api/APIService";

export default {
  name: "Resume",
  components: {DocumentUploader},
  data: () => ({
    files: [],
  }),
  props: {
    currentStep: [Number, String],
    cvs: Array
  },
  methods: {
    updateFiles (files) {
      this.files = files
    },
    selectFile (id) {
      const file = this.cvs.find(file => file.id === id)
      this.cvs.filter(file => file.id !== id).forEach(file => file.selected = false)
      file.selected = !file.selected
    },
    uploadFile (file) {
      console.log(file)
      this.$emit('uploadCv', file)
    }
  },
}
</script>

<style scoped>

</style>