<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialog" max-width="800" @click:outside="close"
            @keypress.esc="close">
    <v-card class="tw-flex flex-column">
      <v-card-title>
        <div class="d-flex tw-w-full mb-5 justify-space-between " :class="[$vuetify.breakpoint.mobile?'flex-column-reverse':'align-center']">
          <h4  >Apply for {{ jobRole }}</h4>
          <v-btn class="mb-3 ml-n2" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <progress-steps :current="currentStep" :steps="steps"/>
      </v-card-title>
      <v-card-text class="d-flex justify-center align-center">
        <div :class="[$vuetify.breakpoint.mobile?'mt-4':'']"  class="tw-w-full">
          <keep-alive>
            <component
                :is="getComponent"
                @selected-cv="selectedCV = $event"
                :shortlisting-criteria="shortlistingCriteria"
                @additional-info="additionalInfo = $event"
                :current-step="currentStep"
                :additional-info="additionalInfo"
                :cvs="cvs"
                :cover-letter="coverLetter"
                @document="coverLetter = $event"
                :resume="cvs.find(cv => cv.selected)"
                @uploadCv="addToCV"
                @step-back="currentStep = $event"
            />
          </keep-alive>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-5 ">
        <div v-if="$vuetify.breakpoint.smAndDown"
             class="tw-fixed mr-n4 mb-4 tw-flex px-5 py-3 tw-bg-white mobile__apply-btn tw-w-full">
          <div class="mr-4 tw-w-1/2">
            <v-btn depressed large block outlined color="#008eef" class="text-capitalize" @click="prevStep">Back</v-btn>
          </div>
          <div class="tw-w-1/2">
            <v-btn depressed large block color="#008eef" :loading="applying" class="text-capitalize white--text"
                   @click="nextStep">{{getButtonText}}
            </v-btn>
          </div>
        </div>
        <div v-else class="d-flex">
          <v-btn outlined color="#008eef" class="text-capitalize mr-4" @click="prevStep">Back</v-btn>
          <v-btn  color="#008eef" :loading="applying" depressed class="text-capitalize white--text" @click="nextStep">{{getButtonText}}
          </v-btn>
        </div>
      </v-card-actions>
      <success-modal :dialog2="display" @close="display=false" :company-name="companyName" :job-title="jobRole"/>
    </v-card>
  </v-dialog>
</template>

<script>
import ProgressSteps from "@/components/reusables/ProgressSteps";
// import Profile from "@/components/dashboard/jobs/apply/Profile";
import AdditionalInfo from "@/components/dashboard/jobs/apply/AdditionalInfo"
import Resume from "@/components/dashboard/jobs/apply/Resume";
import {applyForJob, getCandidateCVs, submitApplicantResponse, uploadCV} from "@/services/api/APIService";
import eventBus from "@/eventBus";
import PreviewApplicationData from "@/components/dashboard/jobs/apply/PreviewApplicationData";
import SuccessModal from "@/components/dashboard/SuccessModal";
// import AdditionalDoc from "@/components/dashboard/jobs/apply/AdditionalDoc";

const FIRST_STEP = 1
// const LAST_STEP = 4

export default {
  name: "ApplyForJobModal",
  components: {SuccessModal, ProgressSteps},
  props: {
    jobRole: String,
    companyName: String,
    dialog: Boolean,
    shortlistingCriteria: Object,
    siteName: String,
    showAdditionalForm: Boolean,
  },
  data: () => ({
    currentStep: 1,
    applying: false,
    additionalInfo: {},
    selectedCV: {},
    cvs: [],
    display: false,
    coverLetter: {},
    isValid: true,
    components: {
      // 1: Resume,
      // 2: AdditionalDoc,
      // 3: AdditionalInfo,
      // 4: PreviewApplicationData
    },
    // actionsForEachStep: [
    //     this.moveNextFromResume
    // ]
  }),
  computed: {
    getComponent() {
      return this.components[this.currentStep][0]
    },
    getButtonText(){
     if(this.getComponent === PreviewApplicationData){
       return 'Submit'
     }
     else{
       return 'Next'
     }
    },
    steps() {
      return Object.keys(this.components).length
    },
    getUserDetails() {
      return JSON.parse(sessionStorage.getItem('userData')) || null
    }
    // components () {
    //   let stepsAndActions = {
    //     1: [Resume, this.moveNextFromResume],
    //     2: [AdditionalDoc, this.moveNextFromAdditionalDocument],
    //     3: [AdditionalInfo, this.moveNextFromAdditionalInfo],
    //     4: [PreviewApplicationData, this.submit()]
    //   }
    //   if (this.showAdditionalForm) {
    //     stepsAndActions = {
    //       1: [Resume, this.moveNextFromResume],
    //       2: [AdditionalDoc, this.moveNextFromAdditionalDocument],
    //       3: [PreviewApplicationData, this.submit()]
    //     }
    //   }
    //   return stepsAndActions
    // }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async nextStep() {
      this.components[this.currentStep][1]()
    },
    prevStep() {
      if (this.currentStep !== FIRST_STEP) {
        this.currentStep--
      }
    },
    async submit() {
      eventBus.$emit('submit')
      this.applying = true
      setTimeout(async () => {
        if (!this.isValid) {
          this.applying = false
          throw new Error("invalid submission")
        }
        try {
          if (this.showAdditionalForm) {
            const possibleCriteria = {
              'How many years of experience do you have?': 'use_years_of_experience',
              'How old are you?': 'use_age',
              'What is your highest level of education attained?': 'use_highest_level_of_education',
              'Location?': 'use_location',
              'What is your salary expectation?': 'use_salary',
              'What are the certifications that you have?': 'use_certification'
            }
            if(Object.keys(this.additionalInfo).length) {
              const response = Object.keys(this.additionalInfo)
                  .map(criterion => ({[possibleCriteria[criterion] ?? criterion]: this.additionalInfo[criterion]}))
                  .reduce((acc, curr) => {
                    const [responseKey] = Object.keys(curr)
                    const JSONTypes = ['use_certification']
                    return {
                      ...acc,
                      // stringify types that are not string
                      [responseKey]: JSONTypes.includes(responseKey) ? JSON.stringify(curr[responseKey]) : curr[responseKey]
                    }
                  })
              console.log('submit',response)

              await submitApplicantResponse({job_id: this.$route.query.jobId, response})
            }
          }
          let cv
          if(this.cvs.find(cv => cv.selected) || null){
            cv = this.cvs.find(cv => cv.selected).cv_url
          }
          else {
            cv = null
          }
          await applyForJob({
            job_id: this.$route.query.jobId,
            // years_of_industry_experience: +this.additionalInfo["How many years of experience do you have?"],
            cv_url: cv,
            site_name: this.siteName,
            job_name: this.jobRole

          }).then(res=>{
            console.log(res)
            // @todo lemon's hack
            this.clearAllForm()
            this.$displaySnackbar({message: "You have successfully applied for this job", success: true})
            this.display = true
            this.$emit('applied')
            this.close()
          })

          // await this.$router.push({name:'SearchResult'})
        } catch (e) {
          this.$displaySnackbar({message: e.message || e.response.data.detail, success: false})
        } finally {
          this.applying = false
        }
      }, 500)
    },
    clearAllForm() {
      eventBus.$emit('clear-form')
      this.coverLetter = {}
      this.currentStep = 1
      this.cvs.forEach(cv => cv.selected = false)
    },
    moveNextFromResume() {
      // if (!this.cvs.some(cv => cv.selected)) {
      //   this.$displaySnackbar({message: "You must select a resume for this job", success: false})
      //   return
      // }
      this.currentStep++
    },
    async moveNextFromAdditionalDocument() {
      // try {
      //   const { data } = await uploadCV({...this.coverLetter, ...this.selectedCV})
      //   console.log(data)
      // } catch (e) {
      //   return this.$displaySnackbar({message: e.response.data.detail})
      // }
      this.currentStep++
    },
    moveNextFromAdditionalInfo() {
      eventBus.$emit('next')
      setTimeout(() => {
        if (Object.keys(this.additionalInfo).some(infoKey => {
          if (typeof this.additionalInfo[infoKey] === 'string')
            return !this.additionalInfo[infoKey].trim()
          return !this.additionalInfo[infoKey].length
        })) {
          return this.$displaySnackbar({message: "Make sure all fields are filled."})
        }
        this.currentStep++
      }, 500)
    },
    async addToCV({name, size: cv_size, url: cv_url, format: cv_format}) {
      try {
        const {data} = await uploadCV({name, cv_format, cv_url, cv_size})
        data.selected = true
        this.cvs.forEach(cv => cv.selected = false)
        this.cvs.push(data)
      } catch (e) {
        this.$displaySnackbar({message: e.response.data.detail})
      }
    }
  },
  watch: {
    dialog: {
      handler(val){
        if(val){
          eventBus.$emit('clear-form')
          this.coverLetter = {}
          this.currentStep = 1
          this.cvs.forEach(cv => cv.selected = false)
        }
      },
      immediate: true
    },
    currentStep: {
      handler: async function (step) {
        if (step === FIRST_STEP && !this.cvs.length) {
          try {
            this.fetchingResume = true
            console.log('watch')
            if (this.getUserDetails) {
              const {data} = await getCandidateCVs()

              this.cvs = data.map(cv => ({
                ...cv,
                cv_size: cv.cv_size,
                name: cv.name + '.' + cv.cv_format,
                selected: false
              }))
            }
          } catch (e) {
            this.$displaySnackbar({message: e.response.detail, success: false})
          } finally {
            this.fetchingResume = false
          }
        }
      },
      immediate: true
    },
    showAdditionalForm: {
      handler: function (value) {
        if (!value) {
          this.components = {
            1: [Resume, this.moveNextFromResume],
            // 2: [AdditionalDoc, this.moveNextFromAdditionalDocument],
            2: [PreviewApplicationData, this.submit]
          }
        } else {
          this.components = {
            1: [Resume, this.moveNextFromResume],
            // 2: [AdditionalDoc, this.moveNextFromAdditionalDocument],
            2: [AdditionalInfo, this.moveNextFromAdditionalInfo],
            3: [PreviewApplicationData, this.submit]
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.v-card__text {
  //height: 400px;
  //overflow: auto;
  //padding-top: 40px !important;
  //margin-top: 40px !important;
}

.v-card__actions {
  border-top: 1px solid #D0DCE4;
  overflow-y: auto;
  @media (max-width: 600px) {
    border-top: none;
  }
}
.mobile__apply-btn {
  bottom: 0;

  @media (min-width: 600px) {
    display: none;
  }
}
</style>
