<template>
  <div class="filter__option__picker--wrapper" v-click-outside="hide">
    <div
        class="dropdown-filter-option-picker tw-cursor-pointer"
        :class="[{'is--selected': !!selected.length}]"
        @click="dropdown = true"
    >
      <div class="d-flex align-center">
        <span class="text-no-wrap">{{ filterName }}</span>
        <vue-feather type="chevron-down" class="ml-1" size="20" />
      </div>
    </div>
    <div v-if="dropdown" class="items__menu px-5 tw-position-absolute">
      <div class="item" v-for="(option, i) in options" :key="i">
        <v-checkbox color="#8F47F4" :label="option" :disabled="singleSelect && !!selected.length && !selected.includes(option)" v-model="selected" :value="option">
          <template #label>
            <span class="item__value">{{option}}</span>
          </template>
        </v-checkbox>
      </div>
      <div class="d-flex justify-center">
        <span class="clear__filter tw-cursor-pointer" @click="clearFilters">
          Clear Filter
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "@/eventBus";

export default {
  name: "DropdownFilterOptionPicker",
  data: () => ({
    dropdown: false,
    selected: []
  }),
  props: {
    options: {
      type: Array,
      required: true
    },
    filterName: {
      type: String,
      required: true
    },
    singleSelect: Boolean
  },
  mounted() {
    eventBus.$on('clear-filters', this.clearFilters)
  },
  methods: {
    hide () {
      this.dropdown = false
    },
    emitUpdate () {
      this.$emit('input', this.selected)
    },
    clearFilters () {
      this.selected = []
    }
  },
  watch: {
    selected: 'emitUpdate'
  }
}
</script>

<style scoped lang="scss">
.dropdown-filter-option-picker {
  border: 1px solid #D0DCE4;
  padding: 4px 12px;
  border-radius: 16px;
  transition: all .3s ease;

  &.is--selected {
    background: #0C8B99 !important;
    color: white !important;
    border-color: #0C8B99;
    font-weight: 600;
  }
}

.clear__filter {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: #1E323F;
  margin-top: 10px;
}

.items__menu {
  box-shadow: 0px 8px 16px #F0F9FF;
  padding: 10px 8px;
  background: #FFF;
  min-width: 200px;
  z-index: 10;
  position: absolute;
}

.filter__option__picker--wrapper {
  position: relative;
}

.item {
  border-bottom: 1px solid #FBFBFB;

  .item__value {
    font-weight: 400;
    font-size: 14px;
    color: #1E323F;
  }
}
</style>