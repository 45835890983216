<template>
  <div>
    <h3>ADDITIONAL INFORMATION</h3>
    <div class="fields mt-12">
      <div v-for="(criteria, i) in additionalInfo" :key="i">
        <label>{{criteria.question}}</label>
        <v-text-field
          v-if="!criteria.items"
          solo
          :type="criteria.type"
          v-model="criteria.answer"
          />
        <v-autocomplete
          v-else
          solo
          :chips="criteria.multiple"
          :items="criteria.items"
          :multiple="criteria.multiple"
          append-icon="mdi-chevron-down"
          :menu-props="{bottom: true, offsetY: true}"
          v-model="criteria.answer"
          />
      </div>
    </div>
  </div>
</template>

<script>
// import {getJobShortlistingCriteria} from "@/services/api/APIService";

import eventBus from "@/eventBus";
import certifications from "@/constantData/certifications";
// import states from "@/constantData/states";
import levels from "@/constantData/levels";
import {getAllCountries} from "@/services/api/APIService";

export default {
  name: "AdditionalInfo",
  data: () => ({
    additionalInfo: [],
    countries:["Lagos, Nigeria"],
  }),
  created() {
    this.getAllCountries()
  },
  async mounted () {
    eventBus.$on('next', () => {
      this.$emit('additional-info', this.additionalInfo.reduce((acc, curr) => ({...acc, [curr.question.toString()]: curr.answer}), {}))
    })
    eventBus.$on('clear-form', () => {
      this.additionalInfo = []
    })
    // eventBus.$on('next', (currentStep) => {
    //   if (currentStep === 3 && this.additionalInfo.some(info => {
    //     console.log(info.answer)
    //     return !info.answer.trim()
    //   })) {
    //     console.log(currentStep)
    //     eventBus.$emit('failed-step')
    //     this.$displaySnackbar({message: "Make sure all fields are filled."})
    //   }
    // })
  },
  props: {
    shortlistingCriteria: Object,
    currentStep: [Number, String]
  },
  methods: {
    getAllCountries() {
      getAllCountries().then(res => {
        res.data.data.map((country) => {
          country.states.map((state) => {
          this.countries.push(state.name + ", "  + country.name)
          })
        })
      })
          .catch(err => {
            console.log(err.response.data.detail)
          })
    },
    returnAdditionalInfoObject (criterion) {
      if (typeof criterion === 'string') {
        criterion = (function () {
          const split = criterion.split('_')
          split.shift()
          return split.join('_')
        }())
      }

      const possibleCriteria = {
        'years_of_experience': {
          title: 'How many years of experience do you have?',
          type: 'number'
        },
        'age': {
          title: 'How old are you?',
          type: 'number'
        },
        'highest_level_of_education': {
          title: 'What is your highest level of education attained?',
          type: 'text'
        },
        'location': {
          title: 'Location?',
          type: 'text'
        },
        'salary': {
          title: 'What is your salary expectation?',
          type: 'number'
        },
        'certification': {
          title: 'What are the certifications that you have?',
          type: 'text'
        }
      }

      const getFieldItems = () => {
        const items = {
          certification: certifications,
          highest_level_of_education: levels,
          location: this.countries
        }
        // return options for recommended criteria that have items and for custom ones that have options
        return items[criterion] ?? (function () {
          if (typeof criterion === 'object') {
            return criterion.options
          }
        }())
      }

      const shouldBeMultiple = () => {
        const multipleFields = ['certification']
        return multipleFields.includes(criterion)
      }

      const getTypeOfCustomCriterion = type => type !== 'numeric' ? 'text' : 'number'

      return {
        question: possibleCriteria[criterion]?.title ?? criterion.content,
        answer: '',
        type: possibleCriteria[criterion]?.type ?? getTypeOfCustomCriterion(criterion.question_type),
        items: getFieldItems(),
        multiple: shouldBeMultiple()
      }
    }
  //
  },
  watch: {
    shortlistingCriteria: {
      handler: function (shortlistingCriteria) {
        if (!shortlistingCriteria) return
        const criteriaToBeUsed = []
        for (const [p, v] of Object.entries(shortlistingCriteria)) {
          if (p.startsWith('use_') && v) {
            criteriaToBeUsed.push(p)
          }
        }
        this.additionalInfo = criteriaToBeUsed
            .map(this.returnAdditionalInfoObject)
            .concat(shortlistingCriteria.otherShortlistingCriteria
                .map(this.returnAdditionalInfoObject))
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
  .fields {
    //margin-top: 100px !important;
  }
</style>
