<template>
  <div :class=" ['d-flex', 'justify-space-between', 'tw-w-full', $vuetify.breakpoint.smAndDown?'tw-mt-24 scroll':'']">
    <div class="lg:tw-pl-80 preference tw-w-full align-center">
      <div class="d-flex">
        <dropdown-filter-option-picker
            v-for="(filter, i) in filterOptions"
            :key="i"
            v-model="filterOptions[i].selected"
            :single-select="filter.singleSelect"
            class="mr-5"
            :filter-name="filter.filterName"
            :options="filter.options"/>
      </div>
      <div>
        <v-btn class="text-capitalize" text @click="clearFilters">Reset Filters</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownFilterOptionPicker from "@/components/dashboard/reuseables/DropdownFilterOptionPicker";
import _ from 'lodash';
import eventBus from "@/eventBus";
import {mapActions} from "vuex";

export default {
  name: "TopNavSearch",
  components: {DropdownFilterOptionPicker},
  props: {
    field: Array
  },
  data: () => ({
    filterOptions: [
      {
        filterName: 'Job Type',
        options: ['Full-Time', 'Part-Time', 'Contract', 'Internship', 'Flexible'],
        selected: []
      },
      {
        filterName: 'Experience Level',
        options: ["Intern","Entry","Junior","Mid","Senior","Lead","Director","Executive"],
        selected: []
      },
      {
        filterName: 'Date Posted',
        options: ['Today', 'This Week', 'This Month', 'This Quarter', 'Anytime'],
        selected: [],
        singleSelect: true
      },
      {
        filterName: 'On-Site/Remote',
        options: ['On-Site', 'Remote'],
        selected: [],
        singleSelect: true
      }
    ]
  }),
  created() {
    this.debouncedFilterJobs = _.debounce(this.getFilterJobs, 1000)
  },
  watch: {
    filterOptions: {
      handler: function (filterOptions) {
        this.debouncedFilterJobs(filterOptions)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('jobSearch', ['filterJobs']),
    clearFilters () {
      eventBus.$emit('clear-filters')
    },
    getFilterJobs (filterOptions) {
      this.filterJobs(this.preparePayload(filterOptions))
    },
    preparePayload (filterOptions) {

      const getKey = name => {
        if (name === 'On-Site/Remote') return 'location_mode'
        return name.split(' ').join('_').toLowerCase()
      }

      const getValue = (name, selected) => {
        const dates_posted = {
          'today': 0,
          'this week': 7,
          'this month': 30,
          'this quarter': 90
        }

        const values = {
          'Date Posted': dates_posted[selected[0].toLowerCase()],
          'On-Site/Remote': selected.map(s => s === 'On-Site' ? 'on_site' : s).join(',').toLowerCase()
        }
        return values[name] ?? selected.join(',')
      }

      return filterOptions
          .filter(({ selected }) => !_.isEmpty(selected))
          .reduce((acc, { filterName, selected }) => {
            return {
              ...acc,
              [getKey(filterName)]: getValue(filterName, selected)
            }
          }, {})
    }
  }
}
</script>

<style scoped lang="scss">
.mobileCard {
  border-radius: 32px 32px 0px 0px;
}

.scroll {
  max-width: 87vh;
  overflow-x: auto;
}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  display: none;
  height: 1px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 15px !important;
  border-radius: 6px;
}

.preference {
  display: flex;
  height: 76px;
  background: #FFFFFF;
  box-shadow: 0px 3px 5px 1px rgba(0, 142, 239, 0.08);
  flex-direction: row;
  @media (max-width: 768px) {
    display: contents;
    height: fit-content;
  }
}

.mobileFilter {
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
}

.jobWrapper {
  .some-style > > > .v-input__slot::before {
    border-style: none !important;
  }

  ::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border: none !important;
  }

  ::v-deep .v-application .primary--text {
    color: red !important;
  }


}
.changeColor{
  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #0C8B99 !important;
  }
}
::v-deep .v-dialog:not(.v-dialog--fullscreen).v-dialog {
  margin: 0 !important;
  max-height: 100%;
  @media screen and (min-width: 1024px) {
    display: none !important;
  }
}

.theme--light.v-application {
  @media screen and (min-width: 1024px) {
    display: none;
  }
}

::v-deep .theme--light.v-application .theme--dark.v-overlay {
  display: none !important;
}

.v-dialog__content {
  top: 50% !important;
}

.filterTitle {
  background: #FFFFFF;
  border: 1px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 8px;
  line-height: 28px;
  color: #142E70;
}

.filterHeader {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;

}

.filterTitleOnClick {
  background: #0C8B99;
  border: 1px solid #142E70;
  box-sizing: border-box;
  border-radius: 8px;

}
</style>
