<template>
<div>
  <div>
    <v-dialog
        v-model="dialog2"
        max-width="430"
        transition="dialog-top-transition"

    >
      <v-card class="tw-p-3 md:tw-p-5 card">
        <div class="tw-flex tw-justify-end">
          <v-btn icon text @click="close">
            <v-icon color="black" class="ml-2">mdi-close</v-icon>
          </v-btn>
        </div>

        <div class="inner-content">
          <div class="mb-8">
            <checked-icon/>
          </div>
          <h3 class="inner-title tw-w-96">{{ title }}{{companyName}}</h3>
          <base-button class=" tw-mt-6 tw-cursor-pointer" button-text="Go to Next Step" @click="goto" width="171"/>
        </div>
      </v-card>
    </v-dialog>
  </div>

</div>
</template>

<script>
import CheckedIcon from "@/components/icons/checkedIcon";
import BaseButton from "@/components/reusables/BaseButton";


export default {
  name: "SuccessModal",
  components: {BaseButton, CheckedIcon},
  data: ()=> {
    return {
            title: "Your application has been sent \n" +
                  " to ",
    }
  },
  props:{
    dialog2: [Boolean],
    companyName: [String],
    jobTitle: [String]
  },
  methods:{
    close() {
      this.$emit('close')
    },
    goto(){
      this.$router.push({path:`/dashboard/jobs/applied/${this.$route.query.jobId}`,query:{company_name:`${this.companyName}`, job_title:`${this.jobTitle}`}})
    }
  }
}
</script>

<style scoped>
.card{
  min-height: 359px;
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
}
.inner-title{
  width: 263px;
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #475661
}
.subtext{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #008EEF;
}
.inner-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>