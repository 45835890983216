<template>
  <div class="d-flex steps__wrapper">
    <div v-for="(step) in steps" :key="step" :class="{'active': current >= step}" class="enum-step mr-4"/>
  </div>
</template>

<script>
export default {
  name: "ProgressSteps",
  props: {
    steps: Number,
    current: Number
  }
}
</script>

<style scoped lang="scss">
.steps__wrapper {
  max-width: 500px;
  width: 100%;
}
.enum-step {
  flex-grow: 1;
  height: 4px;
  border-radius: 8px;
  background-color: #F2E9FF;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 0;
    height: 100%;
    border-radius: 8px;
    background-color: #8F47F4;
    transition: all .2s ease-in;
  }

  &.active {

    &:before {
      width: 100%;
    }
  }
}
</style>