<template>
  <div>
    <div v-if="isLoading" class="tw-w-full applyFlexCenterWithHeight">
      <v-progress-circular
          indeterminate
          color="#008eef"/>
    </div>
    <div v-else :class="[$vuetify.breakpoint.smAndDown?'':'tw-flex']" id="jobDetailsInfo">
      <div class="lg:tw-block tw-hidden">
        <ExploreBySearch @scrollToTop="scrollNow" :search="search" :explore_by_search="filteredSearchData"
                         @saving="toggleSavingPreference" @loading="toggleLoadingPreference" class="pr-4"/>
      </div>
      <div :class="[$vuetify.breakpoint.smAndDown? '':'lg:pr-10' ]">
        <div
            :class="[$vuetify.breakpoint.smAndDown? '':'flex-wrap', 'flex-md-nowrap', 'wrapper',  'lg:pl-5', 'pl-md-6', 'mt-3', 'mx-auto' ]"
        >


          <div v-if="loading" class="d-flex justify-center tw-w-full py-5">
            <v-skeleton-loader
                :loading="loading"
                class="wrapper"
                type="list-item-three-line, card-avatar, article, actions"
            ></v-skeleton-loader>
          </div>

          <v-card v-else :flat="$vuetify.breakpoint.smAndDown"
                  :loading="loading"
                  :class="[$vuetify.breakpoint.smAndDown? '':'cardGlow', 'flex-grow-1', 'mr-0','mr-md-6', 'mb-4' ]">
            <v-card-text class="card__text-description px-4 px-sm-16">
              <div class="d-flex justify-space-between">
                <div class="d-flex">
                  <div class="organization__logo mr-5">
                    <v-img
                        :src="orgLogo || noImage"
                        width="57"
                        class="rounded"
                        height="57"
                    />
                  </div>
                  <div>
                    <h5 class="job__title">{{ jobRole }}</h5>
                    <small style="cursor: pointer" @click="goToCompanyProfile" class="tw-capitalize time">{{ orgName }} <span
                        class="bullet">&bull;</span> {{ jobType }}</small><span
                      class="bullet ml-1">&bull;</span>
                    <span class="type time ml-1">{{ processDay(createdAt) }} </span>
                    <div class="d-flex mt-3 align-center" style="color: #9CABB5">
                      <svg class="mr-1 ml-n1" width="17" height="16" viewBox="0 0 17 16" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                          <path
                              d="M14.7266 6.66699C14.7266 11.3337 8.72656 15.3337 8.72656 15.3337C8.72656 15.3337 2.72656 11.3337 2.72656 6.66699C2.72656 5.07569 3.3587 3.54957 4.48392 2.42435C5.60914 1.29913 7.13526 0.666992 8.72656 0.666992C10.3179 0.666992 11.844 1.29913 12.9692 2.42435C14.0944 3.54957 14.7266 5.07569 14.7266 6.66699Z"
                              stroke="#9CABB5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path
                              d="M8.72656 8.66699C9.83113 8.66699 10.7266 7.77156 10.7266 6.66699C10.7266 5.56242 9.83113 4.66699 8.72656 4.66699C7.62199 4.66699 6.72656 5.56242 6.72656 6.66699C6.72656 7.77156 7.62199 8.66699 8.72656 8.66699Z"
                              stroke="#9CABB5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="16" height="16" fill="white" transform="translate(0.726562)"/>
                          </clipPath>
                        </defs>
                      </svg>
                      {{ location }}
                    </div>
                  </div>
                </div>
                <BaseButton
                    :button-text="getJobState"
                    :disabled="isUserApplied || jobStatus === 'closed'"
                    :class="[isUserApplied?'tw-mx-2':'']"
                    class="text-capitalize ml-5 desktop__apply-btn white--text" color="#008eef"
                    @click="openApplyModal">
                  <template #left-icon>
                    <vue-feather type="check" style="color: white" class="mr-1" v-if="isUserApplied"/>
                  </template>
                </BaseButton>
              </div>
              <div :class="['bookmark__or__share', 'my-5 ml-n4','tw-flex','align-center',]" class="tw-flex tw-w-full">
                <v-btn v-if="getUserDetails" :loading="saveJobLoading"
                       class="d-flex align-center mt-2 "
                       @click="savedJob">
                  <div class="tw-flex tw-items-center pl-6" v-if="!isSaved" :style="{backgroundColor:$vuetify.breakpoint.mobile?'#FBFBFB':'',border:$vuetify.breakpoint.mobile?'': '1px solid #EEEEEE'}" style="width: 118px; height: 48px; letter-spacing: 0;box-sizing: border-box;border-radius: 8px;">
                    <vue-feather type="bookmark" style="width: 24px;height: 24px"/>
                    save
                  </div>
                  <div v-if="isSaved" class="tw-flex tw-items-center tw-px-5"
                       style="text-transform: capitalize;width: max-content;
                      height: 36px; color: #008EEF;letter-spacing: 0;
                       background-color: #F6FCFF; margin-left: -16px">
                    <v-icon size="24" >mdi-bookmark</v-icon>
                    saved
                  </div>
                </v-btn>

                <ShareJobModal :outlined="!$vuetify.breakpoint.mobile" :job-title="jobRole" :id="$route.query.jobId" :logo="orgLogo || noImage"
                               :job-type="jobType" :location="location" :company="orgName" class="mt-2"
                               style="color: #475661"/>

              </div>
              <div class="tw-flex tw-w-full align-center tw-pt-5">
                <div>
                  <h3 class="h3cla">Salary</h3>
                  <!--                <small>{{getSalary(shortlistingCriteria.currency,shortlistingCriteria.salary_lower_limit,shortlistingCriteria.salary_upper_limit)}} </small>-->
                  <small>{{getSalary(jobCurrency,minimumSalary,maximumSalary)}} </small>
                </div>
                <div class="tw-ml-16">
                  <h3 class="h3cla">Level</h3>
                  <small>{{experienceLevel}}</small>
                </div>

              </div>
              <div style="word-break: break-word !important;" class="job-description mt-14">
                <div>
                  <h5 class="font-weight-medium mb-3">Job Description</h5>
                  <div class="tw-mb-5" v-html="jobDescription"></div>
                </div>
                <div v-if="jobResponsibilities">
                  <h5 class="font-weight-medium mb-3">Job Responsibility</h5>
                  <div class="tw-mb-5" v-html="jobResponsibilities"></div>
                </div>
                <div v-if="additionalInformation" class="mb-10">
                  <h5 class="font-weight-medium">Additional Information</h5>
                  <div
                      class=" tw-flex-wrap tw-items-center tw-py-1">
                    <!--                  <div class="tw-mb-1" >This role comes with;</div>-->
                    <div class="tw-pl-3" v-html="additionalInformation"></div>
                    <!--                  <div class="tw-pl-3" v-for="(info, i) in additionalInformation.split('\n')" :key="i">-->
                    <!--                    <li type="disc" class="text-capitalize" >{{info}}</li>-->
                    <!--                  </div>-->
                  </div>
                </div>


                <BaseButton
                    :disabled="isUserApplied  || jobStatus === 'closed'"
                    :button-text="getJobState"
                    class="text-capitalize desktop__apply-btn white--text" color="#008eef"
                    :class="[isUserApplied?'tw-mx-2':'']"
                    @click="openApplyModal">
                  <template #left-icon>
                    <vue-feather type="check" style="color: white" class="mr-1" v-if="isUserApplied"/>
                  </template>
                </BaseButton>
              </div>
            </v-card-text>
          </v-card>


          <div class="tw-position-fixed pb-16 mb-5">

            <div v-if="loading" class="d-flex justify-center tw-w-full py-5">
              <v-skeleton-loader
                  :loading="loading"
                  class="wrapper"
                  type="card-avatar, article, actions"
              ></v-skeleton-loader>
            </div>
            <v-card v-else :flat="$vuetify.breakpoint.smAndDown"
                    :class="[$vuetify.breakpoint.smAndDown? '':'cardGlow', 'flex-grow-1', 'mr-0', 'mr-md-6', ]">

              <v-card-text class="card__text-description px-4 px-sm-16">
                <div class="follow-org d-flex justify-space-between company__info-name">
                  <div class="tw-flex organization__logo mr-5">
                    <v-img
                        :src="orgLogo || noImage"
                        width="57"
                        class="rounded"
                        height="57"
                    />
                    <div class="ml-4">
                      <h4 style="cursor: pointer" @click="goToCompanyProfile">{{ orgName }}</h4>
                      <span>{{ orgCategory }}</span>
                    </div>
                  </div>
                  <BaseButton
                      v-show="getUserDetails"
                      :loading="following"
                      :button-text="isFollower ? 'Following' : 'Follow'"
                      class="text-capitalize"
                      :depressed="isFollower"
                      :class="{'white--text': isFollower}"
                      :color="!isFollower ? '#008eef' : '#475661' "
                      :outlined="!isFollower"
                      height="45"
                      @click="followUnfollow">
                  </BaseButton>
                </div>

                <div class="py-4 tw-flex align-center">
                  <div class="mr-6">
                    <span class="font-weight-bold">{{ followers }}</span> Follower{{ followers > 1 ? 's' : '' }}
                  </div>
                  <div class="d-flex align-center my-2">
                    <vue-feather :size="18" class="mr-3" type="users"/>
                    <span><span class="font-weight-bold">{{ companySize }}</span> Employees</span>
                  </div>

                </div>
                <div class="my-3">
                  <p class="clamp-sentence company__description">{{ companyDesc }}</p>
                </div>
              </v-card-text>
              <v-card-actions v-if="!loading" class="tw-flex card__text-description px-4 px-sm-14">
                <div @click="goToCompanyProfile" style="cursor: pointer" class="mr-5 ml-11">
                  <BaseButton button-text="View Company Profile" text class=" view__company__profile-btn text-capitalize">

                  </BaseButton>
                </div>
                <vue-feather stroke="#008EEF" stroke-width="1.5" class="ml-5" type="arrow-right" right/>
              </v-card-actions>
            </v-card>

          </div>
        </div>

        <div v-if="$vuetify.breakpoint.mobileBreakpoint"
             class="tw-fixed ml-n3 px-5 py-3 tw-bg-white mobile__apply-btn tw-w-full">
          <v-btn :disabled="isUserApplied || jobStatus === 'closed'" @click="openApplyModal" depressed large block class="white--text text-capitalize" color="#008eef">
            <vue-feather type="check" class="mr-1" v-if="isUserApplied"/>
            {{getJobState}}
          </v-btn>
        </div>
        <apply-for-job-modal
            :dialog="dialog"
            :job-role="jobRole"
            :show-additional-form="showAdditionalInfoForm"
            :shortlisting-criteria="shortlistingCriteria"
            :site-name="siteName"
            :company-name="orgName"
            @applied="[setupApplyForm(true)]"
            @close="dialog = false"/>
      </div>
    </div>
  </div>
</template>
<script>
import ShareJobModal from "@/components/dashboard/ShareJobModal";
import {
  followOrUnfollowCompany,
   guestGetCompanySummary,
  isUserAppliedForJob,
} from "@/services/api/APIService";
import {
  getJobDetails,
  getCompanySummary
} from "@/services/api/AuthApiService";
import ApplyForJobModal from "@/components/dashboard/jobs/apply/ApplyForJobModal";
import ExploreBySearch from "@/components/dashboard/reuseables/ExploreBySearch";

import {isJobSaved, savedJob} from "@/services/api/APIService";
import BaseButton from "@/components/reusables/BaseButton";
import {viewFile} from "@/services/utils/aws";
import timeDay from "@/mixins/timeDay.mixin";
import {mapGetters} from "vuex";

export default {
  name: "JobDetailsCard",
  components: {BaseButton, ExploreBySearch, ShareJobModal, ApplyForJobModal},
  mixins: [timeDay],
  data: () => ({
    jobRole: '',
    orgName: '',
    jobType: '',
    orgCategory: '',
    location: '',
    dialog: false,
    jobDescription: '',
    createdAt:'',
    jobResponsibilities:'',
    additionalInformation: '',
    experienceLevel:'',
    followers: 100,
    companySize: '1 - 50',
    companyDesc: 'Semicolon is passionate about identifying talented mindsets and unleashing innovation within them.',
    shortlistingCriteria: {},
    siteName: '',
    orgLogo: '',
    loading: false,
    following: false,
    isUserApplied: false,
    isFollower: true,
    noImage: 'https://images.unsplash.com/photo-1632169505206-9506f48761e6?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
    saveJobLoading: false,
    isSaved: false,
    showAdditionalInfoForm: true,
    jobStatus: "",
    jobCurrency: "",
    maximumSalary: "",
    minimumSalary: "",
  }),
  methods: {
    scrollNow() {
      document.getElementById('jobDetailsInfo').scrollIntoView();
    },
    getSalary(cur,lower,upper){
      console.log(cur,lower,upper)
      if (!(cur | lower | upper)) return 'Not Specified';
      else {
        return this.getCurrency(cur) + this.convertNumber(Math.round(lower)) + ' - ' + this.getCurrency(cur)+ this.convertNumber(Math.round(upper))
      }
    },
    getCurrency(currency) {
      if (!currency) return
      const arr = currency.split(' ')
      const result = arr.slice(-1)

      return result[0].replace(/[()]/g, '')
    },
    convertNumber(x) {
      if (!x) return
      else return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    openApplyModal() {
      if (this.getUserDetails) {
        this.dialog = true
      } else {
        window.location.href = `${process.env.VUE_APP_FRONTEND_AUTH_URL}/auth/login?site=individual&routeName=SearchResult&redirect=${this.$route.fullPath}`

      }
      // this.$router.push({name: 'LoginView', query: {jobId: this.$route.query.jobId,routeName:'SearchResult'}})
    },
    async setupApplyForm(silent = false) {
      const {$route: {query: {jobId}}, getUserDetails: {email}} = this
      !silent && (this.loading = true)
      const jobDetailsProperties = [
        ['jobRole', 'title'],
        ['jobDescription', 'description'],
        ['jobResponsibilities', 'responsibilities'],
        ['additionalInformation', 'additional_info'],
        ['experienceLevel', 'experience_level'],
        ['createdAt', 'created_at'],
        ['jobType', 'type'],
        ['siteName', 'site_name'],
        ['jobStatus','status'],
        ['jobCurrency', 'currency'],
        ['maximumSalary', 'salary_upper_limit'],
        ['minimumSalary', 'salary_lower_limit'],
      ]
      try {
        const {data: jobDetails} = await getJobDetails(jobId)
        jobDetailsProperties.forEach(([innerKey, serverKey]) => {
          this[innerKey] = jobDetails[serverKey]
        })
        const [{data: companySummary}, {data: {has_applied: isUserApplied}}] =
            await Promise.all([
              getCompanySummary({site_name: jobDetails.site_name, candidate: email}),
              isUserAppliedForJob({job_id: jobId, candidate_email: email})
            ])
        this.isUserApplied = isUserApplied
        this.shortlistingCriteria = {
          ...jobDetails.shortlisting_criteria,
          otherShortlistingCriteria: jobDetails.other_shortlisting_criteria === "Not set" ? [] : jobDetails.other_shortlisting_criteria
        }
        const criteriaToBeUsed = Object.entries(this.shortlistingCriteria).filter(([key, value]) => (key.startsWith('use_') && value))
        if (!criteriaToBeUsed.length && !this.shortlistingCriteria.otherShortlistingCriteria.length) this.showAdditionalInfoForm = false
        const companySummaryProperties = [
          ['orgName', 'company_name'],
          ['companyDesc', 'company_overview'],
          ['followers', 'followers'],
          ['companySize', 'company_size'],
          ['isFollower', 'is_follower'],
          ['orgCategory', 'industry'],
          ['orgLogo', 'company_logo'],

          // ['location', 'is_remote']
        ]
        companySummaryProperties.forEach(([innerKey, serverKey]) => {
          if (serverKey === 'company_logo') {
            console.log({ serverKey })
            viewFile({ url: companySummary[serverKey], context: this, property: innerKey })
          } else {
            this[innerKey] = companySummary[serverKey]
          }
        })
        this.location = companySummary.is_remote ? 'Remote' : 'On Site'
      } catch (e) {
        this.$displaySnackbar({message: e.response.data.detail})
      } finally {
        this.loading = false
      }
    },
    async guestSetupApplyForm() {
      const {$route: {query: {jobId}},} = this
      const jobDetailsProperties = [
        ['jobRole', 'title'],
        ['jobDescription', 'description'],
        ['jobResponsibilities', 'responsibilities'],
        ['additionalInformation', 'additional_info'],
        ['createdAt', 'created_at'],
        ['experienceLevel', 'experience_level'],
        ['jobType', 'type'],
        ['siteName', 'site_name'],
        ['jobStatus','status'],
        ['jobCurrency', 'currency'],
        ['maximumSalary', 'salary_upper_limit'],
        ['minimumSalary', 'salary_lower_limit'],
      ]
      try {
        const {data: jobDetails} = await getJobDetails(jobId)
        jobDetailsProperties.forEach(([innerKey, serverKey]) => {
          this[innerKey] = jobDetails[serverKey]
        })
        const [{data: companySummary}] =
            await Promise.all([
              guestGetCompanySummary({site_name: jobDetails.site_name}),
            ])
        this.shortlistingCriteria = {
          ...jobDetails.shortlisting_criteria,
          otherShortlistingCriteria: jobDetails.other_shortlisting_criteria === "Not set" ? [] : jobDetails.other_shortlisting_criteria
        }
        const companySummaryProperties = [
          ['orgName', 'company_name'],
          ['companyDesc', 'company_overview'],
          ['followers', 'followers'],
          ['companySize', 'company_size'],
          ['isFollower', 'is_follower'],
          ['orgCategory', 'industry'],
          ['orgLogo', 'company_logo'],
        ]
        companySummaryProperties.forEach(([innerKey, serverKey]) => {
          if (serverKey === 'company_logo') {
            console.log({ serverKey })
            viewFile({ url: companySummary[serverKey], context: this, property: innerKey })
          } else {
            this[innerKey] = companySummary[serverKey]
          }
        })
        this.location = companySummary.is_remote ? 'Remote' : 'On Site'
      } catch (e) {
        this.$displaySnackbar({message: e.response.data.detail})
      } finally {
        this.loading = false
      }
    },
    async followUnfollow() {
      try {
        this.following = true
        const {data} = await followOrUnfollowCompany({site_name: this.siteName})
        console.log(data)
        const {data: companySummary} = await getCompanySummary({
          site_name: this.siteName,
          candidate: this.getUserDetails.email
        })
        this.followers = companySummary.followers
        this.isFollower = companySummary.is_follower
      } catch (e) {
        this.$displaySnackbar({message: e.response.data.detail})
      } finally {
        this.following = false
      }
    },
    toggleLoadingPreference(id) {
      const job = this.filteredSearchData.find(j => j.id === Number.parseInt(id))
      job.loading = !job.loading
    },
   async toggleSavingPreference(id){
      const job = this.filteredSearchData.find(job => job.id === Number.parseInt(id))
     await this.isJobSaved()
      job.is_saved = !job.is_saved
    },

    savedJob() {
      this.saveJobLoading = true;
      const userData = JSON.parse(sessionStorage.getItem('userData'))
      let data = {}
      this.toggleLoadingPreference(this.$route.query.jobId)
      data.job_id = this.$route.query.jobId
      data.candidate_email = userData.email
      savedJob(data).then(async ()=>{
        await this.toggleSavingPreference(this.$route.query.jobId)
        await this.isJobSaved()
        this.toggleLoadingPreference(this.$route.query.jobId)
        this.saveJobLoading = false
      })
          .catch(err=> {
            let errorKey = Object.keys(err.response.data)
            this.$displaySnackbar({
              message: err.response.data[errorKey[0]],
              success: false
            })
          })
    },
    goToCompanyProfile() {
      let route = sessionStorage.getItem("backRoute")
      if (route) {
        sessionStorage.removeItem("backRoute");
      }
      this.$router.push({name:'ViewCompany', params: {tab: 'description'}, query:{site_name : this.siteName}})
    },
   async isJobSaved() {
     if (this.getUserDetails) {
       let data = {}
       data.email = this.getUserDetails.email
       data.jobId = this.$route.query.jobId
       data.jobId = Number.parseInt(data.jobId)
       await isJobSaved(data).then(res => {
         this.isSaved = res.data.is_saved
       })
           .catch(err => {
             let errorKey = Object.keys(err.response.data)
             this.$displaySnackbar({
               message: err.response.data[errorKey[0]],
               success: false
             })
           })
     }
   }

  },
  props: {
    filteredSearchData: Array,
    search: String
  },

  async created() {
    console.log('black')
    await this.isJobSaved()
    if (this.getUserDetails) {
      console.log(this.getUserDetails)
      await this.setupApplyForm()
    } else await this.guestSetupApplyForm()
  },
  computed: {
    ...mapGetters('jobSearch', ["isLoading"]),
    getUserDetails() {
      return JSON.parse(sessionStorage.getItem('userData'))
    },
    getJobState(){
      let response = ''
      if (this.jobStatus === 'closed'){
        response = 'Closed'
      }
      else if (this.isUserApplied && this.jobStatus !== 'closed'){
        response = 'Applied'
      }
      else if (!this.isUserApplied && this.jobStatus !== 'closed'){
        response = 'Apply'
      }
      return response
    }
  },
  watch: {
    '$route': {
      handler: function () {
        if (this.getUserDetails) {
          this.setupApplyForm()
        } else {
          this.guestSetupApplyForm()
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
//.scroll {
//  max-height: 100vh;
//  overflow-y: auto;
//  overflow-x: hidden;
//}
//
//.scroll::-webkit-scrollbar {
//  background: transparent !important;
//  width: 5px;
//  height: 8px;
//}
//
//.scroll::-webkit-scrollbar-thumb {
//  background: #D0DCE4 !important;
//  max-height: 15px !important;
//  border-radius: 6px;
//}

.wrapper {
  max-width: 1400px;
  min-width: calc(100vw - 40vw);
  min-height: calc(100vh - 64px);
  @media (max-width: 1280px) {
    min-width: auto;
  }
}

.company__info-name {
  padding-bottom: 2rem;
  border-bottom: 1px solid #eaeaea;
}

.clamp-sentence {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.job__title {
  font-family: DM Sans;
  font-style: normal;
  color: #1E323F;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.v-card__text {
  color: #1E323F !important;
}

.bookmark__or__share {

  .v-btn {
    color: #475661;
    font-weight: 700;
    text-transform: capitalize;
    font-family: DM Sans;
    background-color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
  }
}

.job-description {
  font-family: DM Sans;
  font-style: normal;

  & > h5 {
    font-weight: 700;
    color: #1E323F;
    font-size: 16px;
    line-height: 21px;
    //border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }
}

.view__company__profile-btn {
  font-weight: 400 !important;
  color: #008EEF !important;
}

.company__info-name {
  & > h4 {
    font-weight: 700;
    font-size: 16px;

    & + span {
      font-weight: 400;
      color: #475661;
    }
  }
}

.cardGlow {
  border: 1px solid #FBFBFB !important;
  box-sizing: border-box !important;
  box-shadow: 2px 2px 8px 1px rgba(0, 142, 239, 0.08) !important;
  border-radius: 8px !important;
}
.empty{
  min-height: 60vh;
}
.company__description {
  font-weight: 400 !important;
  color: #475661;
  //max-width: fit-content;
}

.organization__info {
  @media (max-width: 350px) {
    min-width: 280px !important;
  }
}

.mobile__apply-btn {
  bottom: 0;

  @media (min-width: 600px) {
    display: none;
  }
}

.desktop__apply-btn {
  display: none;
  @media (min-width: 600px) {
    display: inline-flex;
  }
}
.time{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.h3cla{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
</style>
